import { Component, OnInit } from '@angular/core';

import {
  trigger,
  style,
  animate,
  transition,
  keyframes,
} from '@angular/animations';


@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  animations: [
    trigger('flyIn', [
      transition('void => *',
        animate(500, keyframes([
          style({
            transform: 'translate(-100%, -100px)',
            opacity: 0,
            offset: 0
          }),
          style({
            transform: 'translate(-30px, -30px)',
            opacity: 1,
            offset: 0.3
          }),
          style({
            transform: 'translate(-10px, -10px)',
            opacity: 1,
            offset: 0.7
          }),
          style({
            transform: 'translate(0, 0)',
            opacity: 1,
            offset: 1
          })
        ]))
      ),
    ])
  ]
})

export class ArticleComponent implements OnInit {


  constructor() { }

  ngOnInit() {
  }
}