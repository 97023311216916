import { NavbarService } from './../services/navbar.service';
import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { trigger, style, transition, animate, keyframes } from '@angular/animations';
import { AuthService } from '../services/auth.service';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('dropdown', [
      transition('void => *',
        animate(250, keyframes([
          style({
            //transform: 'translateX(100%)',
            transform: 'scale(0)', //rotate(90deg)
            //width: 0,
            //height: 0,
            offset: 0
          }),
          style({
            //transform: 'translateX(0)',
            offset: 1
          })
        ]))
      ),
      transition('* => void',
        animate(250, keyframes([

          style({
            //transform: 'translateX(0)',
            offset: 0
          }),
          style({
            //transform: 'translateX(100%)',
            transform: 'rotate(90deg)',
            width: 0,
            height: 0,
            right: 0,
            offset: 1
          })
        ]))
      ),
    ])


  ]
})
export class NavbarComponent implements OnInit {
  _dropdown: boolean = false;
  dropdown_sub: boolean = false;

  get dropdown() { return this._dropdown }
  set dropdown(val: boolean) {
    this._dropdown = val;
    this.dropdown_sub = false; //wenn sich dropdown ändert, wird dropdown_sub = false
  }


  faUser = faUser;
  faPrint = faPrint;

  left_SubHome: number = 0;

  @HostListener('document:touchend', ['$event'])
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.elementRef_Nav.nativeElement.contains(event.target)) {
      // this is inside
    } else {
      this.dropdown = false;
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.dropdown = false;
  }

  @ViewChild('nav') elementRef_Nav: ElementRef;
  @ViewChild('navbar') elementRef_NavBar: ElementRef;
  @ViewChild('a_Home') elementRef_a_Home: ElementRef;

  //@ViewChild('innermenu', { static: false }) elementRef_InnerMenue: ElementRef;

  // set dropdown(val: boolean) { this._dropdown = val; this.onNavBarToggleClick() };
  // get dropdown() { return this._dropdown; };

  constructor(private auth: AuthService, private navbarService: NavbarService) { }

  ngOnInit() {
    // this.dropdown = false;
  }


  // onNavBarToggleClick() {
  //   console.log("this.mainNav");
  //   if (this.elementRef_InnerMenue) {
  //     const el: HTMLElement = this.elementRef_InnerMenue.nativeElement;

  //     let compStyles = window.getComputedStyle(document.body);
  //     console.log(compStyles.getPropertyValue('padding-right'));
  //   }

  //   // 
  //   //   this.mainNav.nativeElement.classList.toggle("active");

  //   // if (this.dropdown)
  //   //   this.mainNav.nativeElement.classList.add("dropdown");
  //   // else
  //   //   this.mainNav.nativeElement.classList.remove("dropdown");
  // }

  // onNavBarToggleClick(menu: HTMLElement) {
  //   menu.classList.toggle("active");
  // }

  // direct access to auth procedure from HTML causes Problem when aot compiling
  // ==> Property 'X' is private and only accessible within class 'xyzComponent'
  isAuthenticated(): boolean {
    return this.auth.authenticated();
  }


  @HostListener("window:scroll", ['$event'])
  onWindowScroll(e) {
    //we'll do some stuff here when the window is scrolled

    // console.log (document.body.scrollTop);
    // console.log (document.documentElement.scrollTop);

    let navbar: HTMLElement = this.elementRef_NavBar.nativeElement;
    if ((document.body.scrollTop > 80) || (document.documentElement.scrollTop > 80))
      navbar.classList.remove('attop');
    else
      navbar.classList.add('attop');
  }


  onMenuDefLeft_Click($event: MouseEvent) {

    if ($event.target['id'] == "subHomeSwitch")
      this.dropdown_sub = !this.dropdown_sub
    else {
      this.dropdown = false;
      //this.dropdown_sub = false; // wird automatisch false, weil sich dropdown ändert
    }

    this.left_SubHome = 0;

    if (this.dropdown_sub && !this.dropdown) {
      let rectNav = this.elementRef_Nav.nativeElement.getBoundingClientRect();
      let rectHome = this.elementRef_a_Home.nativeElement.getBoundingClientRect();

      //this.left_SubHome = rectNav.width - (rectHome.x - rectNav.x ) - rectHome.width;
      this.left_SubHome = (rectHome.x - rectNav.x);


    }

  }

}
