<!-- <div class="container" myMatchHeight="matchheight"> -->



<!-- class="print-view" -->
<div class="container" #container>
    <div class="print-only">
        <h1>Fr<span>it</span>z-IT</h1>
        <p>
            Dipl.-Ing.(FH) Günter Fritz<br>
            Auf Horngarten 10<br>
            66706 Perl<br><br>
            Telefon: +49 6867 4669821<br>
            E-Mail: kontakt@fritz-it.eu<br>
        </p>
        <br>
        <hr>
        <br><br>
    </div>



    <div id="article-list">
        <!-- <button class="btn btn-outline-secondary" (click)="openDialog(fullstack)">Full-Stack Entwicklung</button> -->
        <!-- <button class="btn btn-outline-secondary" [routerLink]="['/home/fullstack']">Full-Stack Entwicklung</button>
        <button class="btn btn-outline-secondary" [routerLink]="['/home/mobileapps']">Mobile Apps für iOS und Android</button>
        <button class="btn btn-outline-secondary" [routerLink]="['/home/datenbanken']">Datenbanken</button>
        <button class="btn btn-outline-secondary" [routerLink]="['/home/webdesign']">Webdesign</button>
        <button class="btn btn-outline-secondary" [routerLink]="['/home/access']">Microsoft Access</button>
        <button class="btn btn-outline-secondary" [routerLink]="['/home/excel']">Excel → Datenbank</button>
        <button class="btn btn-outline-secondary" [routerLink]="['/home/keywords']">Stichworte</button> -->
        <button class="btn btn-outline-secondary" (click)="openDialog(fullstack)">Full-Stack Entwicklung</button>
        <button class="btn btn-outline-secondary" (click)="openDialog(mobileapps)">Mobile Apps für iOS und
            Android</button>
        <button class="btn btn-outline-secondary" (click)="openDialog(datenbanken)">Datenbanken</button>
        <button class="btn btn-outline-secondary" (click)="openDialog(webdesign)">Webdesign</button>
        <button class="btn btn-outline-secondary" (click)="openDialog(access)">Microsoft Access</button>
        <button class="btn btn-outline-secondary" (click)="openDialog(excel)">Excel → Datenbank</button>
        <button class="btn btn-outline-secondary" (click)="openDialog(keywords)">Stichworte</button>
    </div>

    <div class="row">
        <div class="col-md-12">
            <h1>
                <!-- Handgefertigte <div style="display: inline-block;">Software-Lösungen für Ihr Unternehmen,</div> -->
                Software-Lösungen
            </h1>
            <br>
            <p>
                gefertigt in Handarbeit und passgenau zugeschnitten auf Ihre individuellen Vorstellungen und
                Bedürfnissen. Mit Ihnen zusammen
                analysieren wir die Abläufe und Prozesse Ihres Unternehmens und optimieren die Software nach Kriterien
                wie Kosten-Effizienz, Zeitersparnis, Fehlervermeidung, Nachhaltigkeit.
                <br><br>
                Unsere Software-Lösungen werden auf Basis moderner Techniken und nachhaltiger Standards mit viel
                Erfahrung
                und professioneller Sorgfalt entwickelt.
                <br><br>
                Wir programmieren Webanwendungen und Internetauftritte, Web-Server, Web-Clients,
                Datenbank-Anwendungen, Mobile Apps für iOS und Android, Software-Tools.
                <br><br>
                Mit mehr als 25 Jahren Erfahrung verfügen wir über ein breites Wissensspektrum vom Gerätetreiber bis zur
                modernen Webanwendung mit Datenbankanbindung. Von MS-Access und VBA über Typescript und jQuery zu
                ASP.NET Core MVC und C# und Angular.
                <br><br>
                Wir beraten und projektieren, programmieren und testen.
                <br><br><br>
            </p>
        </div>
    </div>



    <!-- SCREEN ONLY -->
    <div class="row screen-only">
        <div class="col-lg-4 col-md-6" (click)="openDialog(fullstack)" #a1>
            <app-article *ngIf="showElement(a1, 1)">
                <div *ngTemplateOutlet="fullstackTeaser"></div>
                <p class="pointer">(mehr)</p>
            </app-article>
        </div>

        <div class="col-lg-4 col-md-6" (click)="openDialog(mobileapps)" #a2>
            <app-article *ngIf="showElement(a2, 2)">
                <div *ngTemplateOutlet="mobileappsTeaser"></div>
                <p class="pointer">(mehr)</p>
            </app-article>
        </div>

        <div class="col-lg-4 col-md-6" (click)="openDialog(datenbanken)" #a3>
            <app-article *ngIf="showElement(a3, 3)">
                <div *ngTemplateOutlet="datenbankenTeaser"></div>
                <p>(mehr)</p>
            </app-article>
        </div>

        <div class="col-lg-4 col-md-6" (click)="openDialog(webdesign)" #a4>
            <app-article *ngIf="showElement(a4, 4)">
                <div *ngTemplateOutlet="webdesignTeaser"></div>
                <p>(mehr)</p>
            </app-article>
        </div>

        <div class="col-lg-4 col-md-6" (click)="openDialog(access)" #a5>
            <app-article *ngIf="showElement(a5, 5)">
                <div *ngTemplateOutlet="accessTeaser"></div>
                <p>(mehr)</p>
                <!-- align="end" -->
            </app-article>
        </div>

        <div class="col-lg-4 col-md-6" (click)="openDialog(excel)" #a6>
            <app-article *ngIf="showElement(a6, 6)">
                <div *ngTemplateOutlet="excelTeaser"></div>
                <p>(mehr)</p>
            </app-article>
        </div>

        <div class="col-lg-4 col-md-6" (click)="openDialog(keywords)" #a7>
            <app-article *ngIf="showElement(a7, 7)">
                <div *ngTemplateOutlet="keywordsTeaser"></div>
                <p>(mehr)</p>
            </app-article>
        </div>
    </div>




    <!-- PRINT ONLY -->
    <div class="row print-only">
        <div class="col-md-12">
            <app-article>
                <div *ngTemplateOutlet="fullstack"></div>
            </app-article>
        </div>

        <div class="col-md-12">
            <app-article>
                <div *ngTemplateOutlet="mobileapps"></div>
            </app-article>
        </div>

        <div class="col-md-12">
            <app-article>
                <div *ngTemplateOutlet="datenbanken"></div>
            </app-article>
        </div>

        <div class="col-md-12">
            <app-article>
                <div *ngTemplateOutlet="webdesign"></div>
            </app-article>
        </div>

        <div class="col-md-12">
            <app-article>
                <div *ngTemplateOutlet="access"></div>
            </app-article>
        </div>

        <div class="col-md-12">
            <app-article>
                <div *ngTemplateOutlet="excel"></div>
            </app-article>
        </div>

        <div class="col-md-12">
            <app-article>
                <div *ngTemplateOutlet="keywords"></div>
            </app-article>
        </div>
    </div>
</div>



<ng-template #fullstackTeaser>
    <div class="my-dialog">
        <h1>Full Stack Entwicklung</h1>
        <p>wir bieten:</p>
        <ul>
            <li>Web Server</li>
            <li>Web Clients</li>
            <li>Interfaces</li>
            <li>Software Tools</li>
        </ul>
        <div>
            <p>
                Wir entwickeln Software-Tools, Web-Anwendungen und Internetauftritte, Datenbank-Anwendungen wie
                Web-Server mit RESTful APIs. Schnittstellen und andere verbindende Komponenten. Wir beraten und
                projektieren,
                programmieren und testen.
            </p>
        </div>
    </div>
</ng-template>

<ng-template #fullstack>
    <div class="my-dialog">

        <div mat-dialog-actions align="end">
            <button (click)="closeDialog()" mat-button mat-dialog-close class="btn btn-outline-success">X</button>
        </div>
        <strong>
            <div *ngTemplateOutlet="fullstackTeaser"></div>
        </strong>
        <div mat-dialog-actions>
            <!-- align="end" -->
            <span>
                <button (click)="closeDialog()" mat-button mat-dialog-close
                class="btn btn-outline-success">Schließen</button>
            </span>
            <span>Lesezeichen:<a class="nav-link" (click)="closeDialog()" [routerLink]="['/home/fullstack']">Fritz-IT.EU
                    ->
                    Full Stack</a></span>
        </div>

    </div>
</ng-template>

<ng-template #mobileappsTeaser>
    <div class="my-dialog">
        <h1>Mobile Apps für iOS und Android</h1>
        <p>
            Wir entwickeln Ihre persönliche und individuelle App zum Einsatz in Ihrem Unternehmen oder zur Benutzung
            durch Ihre Kunden.
        </p>
    </div>
</ng-template>

<ng-template #mobileapps>
    <div class="my-dialog">

        <div mat-dialog-actions align="end">
            <button (click)="closeDialog()" mat-button mat-dialog-close class="btn btn-outline-success">X</button>
        </div>
        <strong>
            <div *ngTemplateOutlet="mobileappsTeaser"></div>
        </strong>

        <p>
            Diese kann Ihr <strong>Firmenlogo</strong> tragen und aus dem <strong>Apple App Store</strong> bzw.
            <strong>Google Play
                Store</strong> auf Ihr Smartphone oder Tablet geladen werden.
            <br><br>
            Viele Einsatzgebiete sind denkbar. Eine App, die von internen Mitarbeitern
            genutzt wird. Eine App die von Ihren Kunden geladen wird und somit der Erweiterung und Verbesserung Ihrer
            Internet-Präsenz
            dient, die Kundenbindung stärkt, etc.
            <br><br>
        </p>
        <ul>
            <li>Lokale oder externe Datenbanken können eingebunden werden, z.B. als Teil und Erweiterung Ihres
                Webauftritts.</li>
            <li>Sensordaten können abgefragt und ausgewertet werden.</li>
            <li>Pushbenachrichtigungen können empfangen werden.</li>
            <li>...</li>
        </ul>

        <div mat-dialog-actions>
            <!-- align="end" -->
            <span>
                <button (click)="closeDialog()" mat-button mat-dialog-close
                class="btn btn-outline-success">Schließen</button>
            </span>
            <span>Lesezeichen:<a class="nav-link" (click)="closeDialog()"
                    [routerLink]="['/home/mobileapps']">Fritz-IT.EU ->
                    Mobile Apps</a></span>
        </div>
    </div>
</ng-template>

<ng-template #datenbankenTeaser>
    <div class="my-dialog">
        <h1>Datenbanken</h1>
        <div>
            <p>wir bieten:</p>

            <ul>
                <li>>20 Jahre Erfahrung mit SQL-Datenbanken</li>
                <li>SQL-Server in Verbindung mit MS-Access</li>
                <li>SQL-Server/MySQL in Verbindung mit einer Webanwendung, z.B. ASP.NET Core/Angular</li>
                <li>SQL-Server/MySQL in Verbindung mit Angular / Express</li>
                <li>...</li>
            </ul>

        </div>
    </div>
</ng-template>

<ng-template #datenbanken>
    <div class="my-dialog">

        <div mat-dialog-actions align="end">
            <button (click)="closeDialog()" mat-button mat-dialog-close class="btn btn-outline-success">X</button>
        </div>
        <strong>
            <div *ngTemplateOutlet="datenbankenTeaser"></div>
        </strong>
        <p>
            Seit über 20 Jahren arbeiten wir intensiv mit Datenbank Systemen, vor allem mit dem Microsoft
            SQL-Server. Das Client-Server System wird dabei häufig von Access und SQL-Server
            gebildet. Zunehmend kommen aber auch Webanwendungen wie zuletzt ASP.NET Core/Angular zum Einsatz oder auch
            Alternativen wie MySQL.
            Hierbei ist auch von Vorteil, dass die Datenbank unabhängig vom Frontend funktioniert, so dass
            die
            Datenbank bestehen bleiben kann, während das Access Frontend durch eine Webanwendung ersetzt
            wird.
            <br><br>
            Allgemein gesehen ist eine Datenbank eine Sammlung von Daten. Man könnte also ein Paket von
            Karteikarten mit voller Berechtigung als Datenbank bezeichnen. In der EDV ist mit einer
            Datenbank
            aber ein elektronisches System gemeint, das die Daten in einem Computer-Netzwerk strukturiert
            ablegt
            und dem Benutzer zur Verfügung stellt. Dabei stellt Datenbank selbst den Server dar, der die
            Daten
            einem Anwender (=Client) zur Verfügung stellt bzw. von diesem entgegennimmt.
            <br><br>
            Es gibt verschiedene Modelle. Das am weitesten verbreitete Datenbankmodell ist die "Relationale
            Datenbank". Dabei werden die Daten in mehreren miteinander verknüpften Tabellen abgelegt. Die
            Verknüpfungen (Relationen) verweisen dann auf Daten in einer anderen Tabelle. (Das könnte
            z.B. eine Adressliste sein. Damit eine bestimmte Adresse nicht für jeden Bewohner eines Hauses
            wiederholt werden muss, wird diese nur einmal in einer separaten Liste abgelegt und mit einer
            eindeutigen Nummer versehen. Zusammen mit dem Bewohner wird dann nur noch diese Nummer
            abgespeichert.)
            <br><br>
            Der SQL-Server von Microsoft (bzw. die kostenfrei verfügbare 'Express'-Variante) ist ein Beispiel
            für eine häufig eingesetzte relationale Datenbank. Auf Linux Systemen kommt häufig MySQL zum Einsatz
            aber auch Access selbst kann eine solche relationale Datenbank darstellen.
        </p>

        <div mat-dialog-actions>
            <!-- align="end" -->
            <span>
                <button (click)="closeDialog()" mat-button mat-dialog-close
                class="btn btn-outline-success">Schließen</button>
            </span>
            <span>Lesezeichen: <a class="nav-link" (click)="closeDialog()"
                    [routerLink]="['/home/datenbanken']">Fritz-IT.EU ->
                    Datenbanken</a></span>
        </div>

    </div>
</ng-template>



<ng-template #webdesignTeaser>
    <div class="my-dialog">
        <h1>Webdesign</h1>
        <div>
            <p>wir bieten:</p>

            <ul>
                <li>Modernes Design von Webseiten und Webanwendungen</li>
                <li>Entwicklung von "responsiven" Internetauftritten</li>
                <li>mit Datenbankanbindung</li>
            </ul>

        </div>
    </div>
</ng-template>

<ng-template #webdesign>
    <div class="my-dialog">

        <div mat-dialog-actions align="end">
            <button (click)="closeDialog()" mat-button mat-dialog-close class="btn btn-outline-success">X</button>
        </div>
        <strong>
            <div *ngTemplateOutlet="webdesignTeaser"></div>
        </strong>
        <p>
            Wir erstellen individuelle und passgenau zugeschnitte Webanwendungen für Ihr Unternehmen. Internetauftritte,
            Web-Server, Web-Clients, etc.
        </p>

        <div mat-dialog-actions>
            <!-- align="end" -->
            <span>
                <button (click)="closeDialog()" mat-button mat-dialog-close
                class="btn btn-outline-success">Schließen</button>
            </span>
            <span>Lesezeichen:<a class="nav-link" (click)="closeDialog()" [routerLink]="['/home/webdesign']">Fritz-IT.EU
                    -> Webdesign</a></span>
        </div>
    </div>
</ng-template>




<ng-template #accessTeaser>
    <div class="my-dialog">
        <h1>Microsoft Access</h1>
        <div>
            <p>wir bieten:</p>

            <ul>
                <li>>25 Jahre Erfahrung mit MS-Access</li>
                <li>Neuentwicklungen</li>
                <li>Anbindung an externe Datenbanken</li>
                <li>Erweiterung, Modernisierung, Wartung und Pflege bestehender Projekte</li>
                <li>Migration zu anderen Plattformen (z.B. ASP.NET)</li>
                <li>...</li>
            </ul>

        </div>
    </div>
</ng-template>

<ng-template #access>
    <div class="my-dialog">
        <div mat-dialog-actions align="end">
            <button (click)="closeDialog()" mat-button mat-dialog-close class="btn btn-outline-success">X</button>
        </div>
        <strong>
            <div *ngTemplateOutlet="accessTeaser"></div>
        </strong>
        <p>
            Access bietet eine kostengünstige - weil schnell und effektiv zu projektierende - Möglichkeit
            Datenbankprojekte zu realisieren. Dadurch hat es nach wie vor seine Daseinsberechtigung vor allem als
            Datenbank-Frontend(*), wenn es auch
            zweifellos wesentlich modernere und elegantere Möglichkeiten (z.B. Web-Anwendungen) gibt.
            <br><br>
            Nicht zu unterschätzende Vorteile sind außerdem die gute Integration mit anderen Office Komponenten wie
            Excel oder Outlook. So ist es zum Beispiel möglich, mit relativ geringem Aufwand E-Mails aus Access
            heraus mit individuellem Inhalt zu erstellen oder Daten aus Excel Dateien zu importieren.
            <br><br>
            Unsere Erfahrung reicht mehr als 25 Jahre zurück und umfasst alle Versionen ab 2.0. Wir haben Microsoft
            Access in zahlreichen kleinen bis teilweise sehr umfangreichen Projekten erfolgreich eingesetzt.
            <strong>Dadurch verfügen wir über einen großen Erfahrungsschatz mit aktuellen und ehemaligen Versionen.
                Dieser umfasst zahlreiche Lösungen und Codebeispiele und natürlich die Pflege und Migration von Daten
                und Versionen.</strong>
            <br><br>
            Lizenzkosten halten sich in Grenzen, weil Microsoft eine kostenlose RUNTIME-Version zur Verfügung
            stellt. Hinzu kommt die ebenfalls kostenfreie SQL-Server Express Datenbank von Microsoft, wodurch sich
            durchaus umfangreiche und trotzdem performante Projekte realisieren lassen.
            <br><br><br>
            (*) Als Frontend (Client) bezeichnet man die Bedienoberfläche, also das, was ein Benutzer unmittelbar von
            einer Anwendung zu sehen bekommt. Im Gegensatz dazu enthält das Backend die Daten einer Anwendung. Diese
            werden oft zentral in einer Datenbank (Server) abgelegt und stehen damit gleichzeitig mehreren Anwendern
            zur Verfügung.
        </p>

        <div mat-dialog-actions>
            <!-- align="end" -->
            <span>
                <button (click)="closeDialog()" mat-button mat-dialog-close
                class="btn btn-outline-success">Schließen</button>
            </span>
            <span>Lesezeichen:<a class="nav-link" (click)="closeDialog()" [routerLink]="['/home/access']">Fritz-IT.EU ->
                    MS-Access</a></span>
        </div>

    </div>
</ng-template>




<ng-template #excelTeaser>
    <div class="my-dialog">
        <h1>Excel → Datenbank</h1>
        <p>
            Wir überführen Ihre Excel-Datenblätter in eine moderne Datenbank mit Client-Server Architektur.
        </p>
        <p>
            In vielen Abteilungen mittlerer und größerer Unternehmen werden zum Teil extrem umfangreiche
            Datenmengen in Excel-Dateien abgelegt. Streng gesehen handelt es sich dabei auch um eine Datenbank -
            und da hier häufig die Daten in verschiedenen Datenblättern miteinander verknüpft werden (SVerweis/VLookup),
            auch um eine relationale Datenbank.
        </p>

    </div>
</ng-template>

<ng-template #excel>
    <div class="my-dialog">
        <div mat-dialog-actions align="end">
            <button (click)="closeDialog()" mat-button mat-dialog-close class="btn btn-outline-success">X</button>
        </div>
        <strong>
            <div *ngTemplateOutlet="excelTeaser"></div>
        </strong>
        <p>
            Meist handelt es sich dabei um langjährig gewachsene Projekte, die von den zuständigen Mitarbeitern
            mit langjähriger Erfahrung gepflegt werden. Kompliziert wird es in dem Moment, wenn ein neuer
            Mitarbeiter eingearbeitet werden soll. Dieser muss alle internen Vorgänge (Business-Logik) sowie die
            Funktionsweise der Excel-"Datenbank" bis ins kleinste Detail verstanden haben, bevor er die Pflege
            übernehmen kann.

            Eine "dedizierte" Datenbank mit entsprechendem Frontend bringt hier erhebliche Vorteile:
        </p>
        <br>
        <ul>
            <li>
                Die sogenannte "referentielle Integrität" ist immer gewährleistet. D.h. ein Datenbank-System wie
                der SQL-Server stellt sicher, dass referenzierte Daten bei der Auswahl existieren und existent
                bleiben, solange diese Ziel eines Verweises sind. Wenn ein Excel-Datensatz per SVerweis/VLookup auf
                andere Daten "zeigt", hindert mich niemand daran, diese (anderen Daten) zu löschen oder so zu
                verändern, dass der Verweis ins Leere läuft.
            </li>
            <li>
                Die Businesslogik ist in der Software hinterlegt. Damit sorgt diese durch entsprechende
                Benutzerführung und Plausibilitätsprüfungen dafür, dass Fehleingaben vermieden werden. Der Anwender
                muss nicht mehr alle Vorgänge bis ins Detail verstanden haben, um bestimmte Prozesse (z.B. das
                Einlesen einer Datei) ausführen zu können. Gleichwohl kann das Datenbanksystem den Anwender durch
                entsprechende Hilfetexte dabei unterstützen, die internen Vorgänge und Notwendigkeiten zu erlernen
                und verstehen.
            </li>
            <li>
                Eingebaute Zugriffsmechanismen (SQL-Abfragen) erlauben es, effektiv bestimmte Daten gezielt
                abzufragen, also z.B. (temporär) zu verknüpfen, zu filtern, darzustellen, zu aktualisieren, etc.
            </li>
        </ul>
        <!-- 
            <a class="nav-link" [routerLink]="['/home/excel']">direkter Link</a> 
        -->

        <div mat-dialog-actions>
            <!-- align="end" -->
            <span>
                <button (click)="closeDialog()" mat-button mat-dialog-close
                class="btn btn-outline-success">Schließen</button>
            </span>
            <span>Lesezeichen:<a class="nav-link" (click)="closeDialog()" [routerLink]="['/home/excel']">Fritz-IT.EU ->
                    Excel</a></span>
        </div>

    </div>
</ng-template>




<ng-template #keywordsTeaser>
    <h1>Stichworte</h1>
    <p>
        <span *ngFor="let keyword of myKeywords; last as isLast">{{keyword}}<span *ngIf="!isLast">, </span>
        </span>
    </p>
</ng-template>

<ng-template #keywords>
    <div class="my-dialog">
        <div mat-dialog-actions align="end">
            <button (click)="closeDialog()" mat-button mat-dialog-close class="btn btn-outline-success">X</button>
        </div>

        <h1>Stichworte</h1>
        <ul>
            <li *ngFor="let keyword of myKeywords">{{keyword}}
            </li>
        </ul>

        <div mat-dialog-actions>
            <!-- align="end" -->
            <span>
                <button (click)="closeDialog()" mat-button mat-dialog-close
                class="btn btn-outline-success">Schließen</button>
            </span>

            <span>Lesezeichen:<a class="nav-link" (click)="closeDialog()" [routerLink]="['/home/keywords']">Fritz-IT.EU
                    ->
                    Stichworte</a></span>
        </div>
    </div>
</ng-template>