import { ClientAreaComponent } from './client-area/client-area.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ImpressumComponent } from './impressum/impressum.component';
// import { LeistungenComponent } from './leistungen/leistungen.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ContasyComponent } from './contasy/contasy.component';



const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    useHash: false,
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
};

const routes: Routes = [
  { path: 'home/:article',           component: HomeComponent },
  
  { path: 'fullstack', redirectTo: '/home/fullstack'},
  { path: 'mobileapps', redirectTo: '/home/mobileapps'},
  { path: 'datenbanken', redirectTo: '/home/datenbanken'},
  { path: 'webdesign', redirectTo: '/home/webdesign'},
  { path: 'access', redirectTo: '/home/access'},
  { path: 'excel', redirectTo: '/home/excel'},
  { path: 'keywords', redirectTo: '/home/keywords'},
  
  // { path: 'leistungen',     component: LeistungenComponent },
  { path: 'impressum',      component: ImpressumComponent },
  { path: 'kontakt',        component: ImpressumComponent },
  { path: 'contasy',        component: ContasyComponent },
  { path: 'login',          component: LoginComponent },
  { path: 'client-area',    component: ClientAreaComponent, canActivate: [AuthGuardService] },


  // {path: 'qrtest.html',pathMatch: 'full', redirectTo: 'assets/qrtest/index.html'},
  
  
  { path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

