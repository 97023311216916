import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NavbarService } from '../services/navbar.service';
import { AuthService } from '../services/auth.service';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { ToolsService } from '../services/tools.service';


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  userAgent: string; // Browser: IE, Safari, ...

  faUser = faUser;
  faLock = faLock;

  invalidLogin: boolean;
  errorMessage: string;

  constructor(private navbarService: NavbarService,
    private router: Router,
    private auth: AuthService,
    private tools: ToolsService) {
    navbarService.imageUrl = "../../assets/img/Landschaft3.jpg";

    this.userAgent = tools.getUserAgent();
  }

  ngOnInit() {
  }


  login(form: NgForm) {

    //this.router.navigate(["/"]);
    this.errorMessage = "";

    let credentials = { "username": form.value.username, "password": form.value.password }

    this.auth.login(credentials).subscribe(err => {

      this.invalidLogin = (err != undefined)
      if (!this.invalidLogin) {
        // login erfolgreich
        this.errorMessage = ""

        this.router.navigate(["/client-area"]);
      } else {

        // login NICHT erfolgreich
        if (err.status == 401)
          this.errorMessage = "Benutzername oder Passwort ungültig."
        else  //case "unknown error":
          this.errorMessage = `Login nicht erfolgreich. (${err.status}: ${err.statusText})`;

        //console.log(this.errorMessage);
      }
    })
  }

}
