<!-- <nav-toggle (click)="onNavTriggerClick()" class="icon clearfix" style="display: inline-block; width: 30px; height: 30px;" [(X)]="X" [color]="'black'" [animationType]="'scissors'"></nav-toggle> -->
<nav #nav>
    <div #navbar class="navbar attop">
        <a class="logo" [routerLink]="['/home']">
            <img src="../../assets/img/Logo - Fritz-IT(40)_blue_green_transparent.png" alt="Logo: Fritz-IT">
        </a>
        <nav-toggle class="navbar-toggle" [(X)]="dropdown" [color]="'rgba(0,0,0,0.5)'" [animationType]="'scissors'">
        </nav-toggle>
        <!-- (XChange)="onNavBarToggleClick()" -->

        <div class="innerMenue">
            <div *ngTemplateOutlet="menudef_left"></div>
            <div *ngTemplateOutlet="menudef_right"></div>
        </div>
    </div>

    <div *ngIf="dropdown" class="outerMenue" [@dropdown]>
        <div *ngTemplateOutlet="menudef_left"></div>
        <hr>
        <div *ngTemplateOutlet="menudef_right"></div>
    </div>

    <div *ngIf="dropdown_sub && !dropdown" class="outerMenue" [@dropdown]
        [ngStyle]="{'right':'unset','left.px': left_SubHome}">
        <div *ngTemplateOutlet="menudef_subHome"> </div>
    </div>
</nav>


<ng-template #menudef_subHome>
    <ul class="nav-sub nav-left" (click)="dropdown_sub=false">
        <li>
            <a class="nav-link" [routerLink]="['/home/fullstack']" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Full Stack Entwicklung</a>
        </li>
        <li>
            <a class="nav-link" [routerLink]="['/home/mobileapps']" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Mobile Apps für iOS und Android</a>
        </li>
        <li>
            <a class="nav-link" [routerLink]="['/home/datenbanken']" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Datenbanken</a>
        </li>
        <li>
            <a class="nav-link" [routerLink]="['/home/webdesign']" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Webdesign</a>
        </li>
        <li>
            <a class="nav-link" [routerLink]="['/home/access']" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Microsoft Access</a>
        </li>
        <li>
            <a class="nav-link" [routerLink]="['/home/excel']" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Excel → Datenbank</a>
        </li>
        <li>
            <a class="nav-link" [routerLink]="['/home/keywords']" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Stichworte</a>
        </li>
    </ul>
</ng-template>


<ng-template #menudef_left>
    <ul class="nav-left" (click)="onMenuDefLeft_Click($event)">
        <div>
            <li>
                <a #a_Home class="nav-link" [routerLink]="['/home']" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">Home</a><button class="btn nav-link"
                    id="subHomeSwitch">{{dropdown_sub ? "▲" : "▼"}}</button>
            </li>
        </div>
        <li *ngIf="dropdown && dropdown_sub">
            <div *ngTemplateOutlet="menudef_subHome"></div>
        </li>
        <li *ngIf="isAuthenticated()">
            <a class="nav-link" [routerLink]="['/client-area']" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Kundenbereich</a>
        </li>

        <li>
            <a class="nav-link" [routerLink]="['/impressum']" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Impressum / Kontakt</a>
        </li>
    </ul>
</ng-template>


<ng-template #menudef_right>
    <ul class="nav-right" (click)="dropdown = false">
        <li>
            <a (click)='navbarService.printView = !navbarService.printView' class="nav-link" [routerLink]>
                <fa-icon [ngClass]="navbarService.printView ? ' print-view' : ''" [icon]="faPrint"></fa-icon><span>Druckansicht</span>
            </a>
        </li>
        <li>
            <!-- 🗝 -->
            <a *ngIf="!isAuthenticated(); else logout" (click)='auth.login()' class="nav-link" [routerLink]="['/login']"
                routerLinkActive="active">
                <fa-icon [icon]="faUser"></fa-icon><span>Login</span>
            </a>
            <ng-template #logout>
                <a (click)='auth.logout()' class="nav-link" [routerLink]="['/home']">
                    <fa-icon [icon]="faUser" class="authenticated"></fa-icon><span>Logout</span>
                </a>
            </ng-template>
        </li>
    </ul>
</ng-template>