import { NavbarService } from './services/navbar.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from "@angular/material/dialog";
import {MatSidenavModule} from '@angular/material/sidenav';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarPanelComponent } from './navbar-panel/navbar-panel.component';
import { HomeComponent } from './home/home.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { FooterComponent } from './footer/footer.component';
import { ArticleComponent } from './article/article.component';
import { LeistungenComponent } from './leistungen/leistungen.component';
import { MatchHeightDirective } from './match-height.directive';
import { LoginComponent } from './login/login.component';
import { AuthService } from './services/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { ToolsService } from './services/tools.service';
import { ClientAreaComponent } from './client-area/client-area.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavToggleComponent } from './nav-toggle/nav-toggle.component';
import { ContasyComponent } from './contasy/contasy.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarPanelComponent,
    HomeComponent,
    ImpressumComponent,
    FooterComponent,
    ArticleComponent,
    LeistungenComponent,
    MatchHeightDirective,
    LoginComponent,
    ClientAreaComponent,
    ToggleButtonComponent,
    NavbarComponent,
    NavToggleComponent,
    ContasyComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    MatDialogModule,
    MatSidenavModule
  ],
  providers: [
    AuthService,
    AuthGuardService,
    NavbarService,
    ToolsService
  ],
  bootstrap: [AppComponent],
  entryComponents:[
    
  ]
})
export class AppModule { }


