import { Component, OnInit, HostListener, OnDestroy, ViewChild, AfterViewInit, Renderer2 } from '@angular/core';
import { NavbarService } from '../services/navbar.service';
import { Subscription, timer } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'navbar-panel',
  animations: [
    trigger(
      "scrollMe",
      [
        state(
          "0",
          style({
            backgroundPositionY: "top"
          })
        ),
        state(
          "1",
          style({
            backgroundPositionY: "calc(100% - 200px)"
          })
        ),
        transition("* => 1", animate("4000ms ease-in-out")),
        transition("* => 0", animate("0ms"))
      ]
    )
  ],
  templateUrl: './navbar-panel.component.html',
  styleUrls: ['./navbar-panel.component.scss'],
})

export class NavbarPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('container') elementRef_Container;
  @ViewChild('nav') elementRef_Nav;


  @ViewChild('myDivForBackgroundPic') divRef_BackgroundPic;
  @ViewChild('myDiv2ForBackgroundPic') divRef2_BackgroundPic;

  isImageFixedOnTop: boolean = true;

  //--- Navbar: Erkennung, ob außerhalb der Navbar geklickt wurde ==> Dropdown schließen
  //https://stackoverflow.com/questions/40107008/detect-click-outside-angular-component

  // navbarOpen = false;



  lastNavClickedEvent: Event;

  // --- Animation
  scrollMeState = "0";

  //imageContainer = 'imageContainer';
  imageUrl: string;
  imageSubscription: Subscription;




  // @HostListener('document:touchend', ['$event'])
  // @HostListener('document:click', ['$event'])
  // onClick(event: MouseEvent): void {
  //   if (this.elementRef_Nav.nativeElement.contains(event.target)) {
  //     // this is inside
  //   } else {
  //     if (this.navbarOpen) this.toggleNavbar(false);
  //   }
  // }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setIsImageFixedOnTop();
  }

  setIsImageFixedOnTop() {
    //this.isImageFixedOnTop = (window.innerWidth > 992) && (window.innerHeight > 750);
    this.isImageFixedOnTop = false;
  }

  constructor(private navbarService: NavbarService, private renderer: Renderer2, private auth: AuthService) { }


  // onLogin() {
  //   this.router.navigate(["/login"]);
  // }

  ngOnInit() {
    // this.screenHeight = window.innerHeight;
    // this.screenWidth = window.innerWidth;

    this.setIsImageFixedOnTop();
    this.setImageUrl(this.navbarService.imageUrl);

    this.imageSubscription = this.navbarService.imageUrlUpdated
      .subscribe((imageUrl) => {
        this.setImageUrl(imageUrl);
      });

  }

  ngAfterViewInit() {
    //this.setImageUrl(this.navbarService.ImageUrl);
  }

  ngOnDestroy() {
    this.imageSubscription.unsubscribe();
  }

  // toggleNavbar(show?: boolean) {
  //   this.navbarOpen = show != undefined ? show : !this.navbarOpen;
  // }

  private setImageUrl(imageUrl: string) {
    this.imageUrl = imageUrl;

    if (this.divRef_BackgroundPic) {
      this.renderer.setStyle(this.divRef_BackgroundPic.nativeElement, 'animationName', `scrolldown`)
    }

    if (this.divRef2_BackgroundPic) {
      this.renderer.setStyle(this.divRef2_BackgroundPic.nativeElement, 'animationName', `scrolldown`)
    }

    this.startAnimation();

    // if (this.divReference)
    //   this.renderer.setStyle(this.divReference.nativeElement, 'backgroundImage', `url(${imageUrl})`)
    // if (this.divReference2)
    //   this.renderer.setStyle(this.divReference2.nativeElement, 'backgroundImage', `url(${imageUrl})`)
  }


  startAnimation() {
    this.scrollMeState = "0"; // restart animation
    timer(1000).subscribe(() => this.scrollMeState = "1")
  }

  onScrollMeDone($event) {
    if (this.scrollMeState != "0") this.scrollMeState = "0";
  }

  // direct access to auth procedure from HTML causes Problem when aot compiling
  // ==> Property 'X' is private and only accessible within class 'xyzComponent'
  isAuthenticated(): boolean {
    return this.auth.authenticated();
  }

  @HostListener("window:scroll", ['$event'])
  onWindowScroll(e) {
    let nav: HTMLElement = this.elementRef_Nav.nativeElement;
    if ((document.body.scrollTop > 80) || (document.documentElement.scrollTop > 80))
      nav.classList.remove('attop');
    else
      nav.classList.add('attop');
  }

}