import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { NavbarService } from '../services/navbar.service';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-client-area',
  templateUrl: './client-area.component.html',
  styleUrls: ['./client-area.component.scss']
})
export class ClientAreaComponent implements OnInit {

  faUser = faUser;
  faLock = faLock;


  constructor(private auth: AuthService, private navbarService: NavbarService) {
    navbarService.imageUrl = "../../assets/img/Landschaft1.jpg";
  }

  ngOnInit() {
  }

  // direct access to auth procedure from HTML causes Problem when aot compiling
  // ==> Property 'X' is private and only accessible within class 'xyzComponent'
  isInRole(role: string): boolean {
    return this.auth.isInRole(role);
  }

}
