<div class="container">
  <section id="content">
    <div>
      <i *ngIf="userAgent != 'IE'"></i>
      <h1>Kunden Login</h1>
      <i *ngIf="userAgent != 'IE'"></i>
    </div>
    <div *ngIf="invalidLogin" class="alert alert-danger">{{errorMessage}}</div>
    <form #loginForm="ngForm" (ngSubmit)="login(loginForm)">
      <label class="wrapper">
        <div>
          <fa-icon [icon]="faUser"></fa-icon><input id="username" name="username" ngModel type="text"
            placeholder="Benutzername" required>
        </div>
      </label>
      <label class="wrapper">
        <div>
          <fa-icon [icon]="faLock"></fa-icon><input id="password" name="password" ngModel type="password"
            placeholder="Passwort" required>
        </div>
      </label>
      <!-- <div><input type="submit" value='Log In'></div> -->
      <button class="btn btn-lg btn-primary" type="submit" [disabled]="loginForm.invalid">Einloggen</button>

      <br>
    </form>

    <div class="note">
      <p>
        HINWEIS! Beim Einloggen in den Kundenbereich verwendet unserer Webseite eine Cookie-ähnliche Technologie um die
        Zugangsberechtigung zu hinterlegen. Nach spätestens 24h werden Sie automatisch abgemeldet.
      </p>
      <p>
        Um in den Kundenbereich zu gelangen, benötigen Sie ein Nutzerkonto.
        Sollten Sie noch nicht über ein Nutzerkonto verfügen, kontaktieren Sie uns bitte unter <a
          href='mailto:kontakt@fritz-it.eu'>kontakt@fritz-it.eu</a>.
      </p>
    </div>
  </section>


</div>

<!-- 

<form class="form-signin" #loginForm="ngForm" (ngSubmit)="login(loginForm)">

  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <br><br>
        <h2 class="form-signin-heading">Login</h2>
        <div *ngIf="invalidLogin" class="alert alert-danger">{{errorMessage}}</div>
        <br />
        <label for="username" class="sr-only">Email address</label>
        <input type="email" id="username" name="username" ngModel class="form-control" placeholder="Benutzername"
          required autofocus>
        <br />
        <label for="password" class="sr-only">Password</label>
        <input type="password" id="password" name="password" ngModel class="form-control" placeholder="Passwort"
          required>
        <br />
        <button class="btn btn-lg btn-primary btn-block" type="submit">Einloggen</button>
      </div>
    </div>


    <br>
    <div>
      <p>
        HINWEIS! Beim Einloggen in den Kundenbereich verwendet unserer Webseite eine Cookie-ähnliche Technologie um die
        Zugangsberechtigung zu hinterlegen. Nach spätestens 24h werden Sie automatisch abgemeldet.
      </p>
      <p>
        Um in den Kundenbereich zu gelangen, benötigen Sie ein Nutzerkonto.
        Sollten Sie noch nicht über ein Nutzerkonto verfügen, kontaktieren Sie uns bitte unter <a
          href='mailto:kontakt@fritz-it.eu'>kontakt@fritz-it.eu</a>.
      </p>
    </div>
  </div>




</form> -->