import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contasy',
  templateUrl: './contasy.component.html',
  styleUrls: ['./contasy.component.scss']
})
export class ContasyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
