//inspiriert by: https://codemyui.com/simple-hamburger-menu-x-mark-animation/

import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { trigger, style, transition, animate, query, group, state } from '@angular/animations';

@Component({
  selector: 'nav-toggle',
  templateUrl: './nav-toggle.component.html',
  styleUrls: ['./nav-toggle.component.scss'],
  animations: [
    trigger('bigBang1', [
      state("false", style({ top: '15%', width: '80%', left: '10%', transform: 'rotate(0)' })),
      state("true", style({ top: '43%', width: '100%', left: '0', transform: 'rotate(45deg)' })),

      transition("false => true", [     // ☰ => X
        animate(200, style({ top: '43%', width: '14%', left: '40%' })),
        style({ transform: 'rotate(45deg)' }),
        animate(200, style({ top: '43%', width: '100%', left: '0' })),
      ]),
      transition("true => false", [     // X => ☰    
        animate(200, style({ top: '43%', width: '14%', left: '40%' })),
        style({ transform: 'rotate(0)' }),
        animate(200, style({ top: '15%', width: '80%', left: '10%' })),
      ])
    ]),
    trigger('bigBang2', [
      state("false", style({ top: '43%', width: '80%', left: '10%' })),
      state("true", style({ top: '43%', width: '14%', left: '40%' })),
      //state("open", style({ top: '43%', width: '100%', left: '0', display: 'none' })),

      transition("false => true", [     // ☰ => X
        animate(200, style({ top: '43%', width: '14%', left: '40%' })),
        //animate(900, style({ top: '43%', width: '14%', left: '40%' })), //warten
      ]),
      transition("true => false", [     // X => ☰    
        //animate(600, style({ top: '43%', width: '14%', left: '40%' })),
        animate('200ms 200ms', style({ top: '43%', width: '80%', left: '10%' })), // mit 200ms Verzögerung
      ])
    ]),
    trigger('bigBang3', [
      state("false", style({ top: '71%', width: '80%', left: '10%', transform: 'rotate(0)' })),
      state("true", style({ width: '100%', top: '43%', left: '0', transform: 'rotate(-45deg)' })),

      transition("false => true", [     // ☰ => X
        animate(200, style({ top: '43%', width: '14%', left: '40%' })),
        style({ transform: 'rotate(-45deg)' }),
        animate(200, style({ top: '43%', width: '100%', left: '0' })),
      ]),
      transition("true => false", [     // X => ☰    
        animate(200, style({ top: '43%', width: '14%', left: '40%' })),
        style({ transform: 'rotate(0)' }),
        animate(200, style({ top: '71%', width: '80%', left: '10%' })),
      ])
    ]),

    //===========================

    trigger('scissors1', [
      state("false", style({ top: '15%', width: '80%', left: '10%', transform: 'rotate(0)' })),
      state("true", style({ top: '43%', width: '100%', left: '0', transform: 'rotate(45deg)' })),

      transition("false => true", [     // ☰ => X
        animate(200, style({ top: '43%', width: '80%', left: '10%' })),
        animate(200, style({ top: '43%', width: '100%', left: '0', transform: 'rotate(45deg)' })),
      ]),
      transition("true => false", [     // X => ☰    
        animate(200, style({ top: '43%', width: '80%', left: '10%', transform: 'rotate(0)' })),
        animate(200, style({ top: '15%', width: '80%', left: '10%' })),
      ])
    ]),
    trigger('scissors2', [
      state("false", style({ top: '43%', width: '80%', left: '10%' })),
      state("true", style({ top: '43%', width: '14%', left: '40%' })),
      //state("open", style({ top: '43%', width: '100%', left: '0', display: 'none' })),

      transition("false => true", [     // ☰ => X
        animate(200, style({ top: '43%', width: '80%', left: '10%' })),
        style({ display: 'none' }),
      ]),
      transition("true => false", [     // X => ☰    
        //animate(600, style({ top: '43%', width: '14%', left: '40%' })),
        animate(200, style({})),
        style({ top: '43%', width: '80%', left: '10%' })
      ])
    ]),
    trigger('scissors3', [
      state("false", style({ top: '71%', width: '80%', left: '10%', transform: 'rotate(0)' })),
      state("true", style({ width: '100%', top: '43%', left: '0', transform: 'rotate(-45deg)' })),

      transition("false => true", [     // ☰ => X
        animate(200, style({ top: '43%', width: '80%', left: '10%' })),
        animate(200, style({ top: '43%', width: '100%', left: '0', transform: 'rotate(-45deg)' })),
      ]),
      transition("true => false", [     // X => ☰    
        animate(200, style({ top: '43%', width: '80%', left: '10%', transform: 'rotate(0)' })),
        animate(200, style({ top: '71%', width: '80%', left: '10%' })),
      ])
    ])








  ]
})

// transition("open <=> closed", [     // X => ☰
//   query('i', animate(1000, style({ width: '14%', left: '40%', top: '43%' }))),
//   style({ transform: 'rotate(0)' }),
//   group([
//     query('i', animate(1000, style({ width: '80%', left: '10%' }))),
//     query('i:nth-child(1)', animate(1000, style({ top: '15%' }))),
//     query('i:nth-child(2)', animate(1000, style({ top: '43%' }))),
//     query('i:nth-child(3)', animate(1000, style({ top: '71%' })))
//   ])
// ])


export class NavToggleComponent implements OnInit {
  _isX: boolean = false; //TwoWayBinding
  _animationType: string;

  @Input('animationType')
  get animationType() { return this._animationType; }
  set animationType(val: string) { this._animationType = val.toLowerCase(); }

  @Output('XChange')
  isXChange = new EventEmitter<boolean>();

  @Input('X')
  get isX() { return this._isX; }
  set isX(val: boolean) {
    // console.log(this._isX);
    // console.log(val);
    if (val != this._isX) {
      this._isX = val;
      this.isXChange.emit(this._isX);
    }
  }

  // animationDone(e) { //updateNavTriggerClass
  //   console.log(e);
  //   console.log(this._isX);
  // }

  @Input() color: string = 'black';

  constructor() { }

  ngOnInit() {
  }

  onNavTriggerClick() {
    this.isX = !this.isX;
  }
}







