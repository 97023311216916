import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(protected auth: AuthService, protected router: Router) { }

  canActivate() {    
    if (this.auth.authenticated())
      return true;

    this.router.navigate(["/login"]);
    return false;
  }

}

