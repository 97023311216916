import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {

  //https://www.impressum-generator.de/impressum-generator/
  //Impressum vom Impressum Generator der Kanzlei Hasselbach, Rechtsanwälte für Arbeitsrecht und Familienrecht

  constructor(private navbarService: NavbarService) {
    navbarService.imageUrl = "../../assets/img/Esel.jpg";
    navbarService.imageUrl = null; //gf 2020-11-17

  }

  ngOnInit() {
  }

}
