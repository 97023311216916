import { Component, OnInit, TemplateRef, ViewChild, OnDestroy, AfterViewInit, AfterContentInit, ElementRef } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { NavbarService } from '../services/navbar.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  a: boolean;

  @ViewChild('container', { static: true }) elementRef_Container: ElementRef;

  @ViewChild('fullstack', { static: true }) templateRef_FullStack: TemplateRef<{}>;
  @ViewChild('mobileapps', { static: true }) templateRef_MobileApps: TemplateRef<{}>;
  @ViewChild('datenbanken', { static: true }) templateRef_Datenbanken: TemplateRef<{}>;
  @ViewChild('webdesign', { static: true }) templateRef_Webdesign: TemplateRef<{}>;
  @ViewChild('access', { static: true }) templateRef_Access: TemplateRef<{}>;
  @ViewChild('excel', { static: true }) templateRef_Excel: TemplateRef<{}>;
  @ViewChild('keywords', { static: true }) templateRef_Keywords: TemplateRef<{}>;

  articleSubscription: Subscription;
  dialogSubscription: Subscription;
  printViewSubscription: Subscription;
  showArticle: string;

  flyInCurrentIndex = 0;
  flyInRequestIndex = 0;
  flyInMaxIndex = 8;


  dialogRef: MatDialogRef<{}, any>

  myKeywords: string[] = [
    "Responsive Web Design",
    "Angular/.NET Core/SQL-Server",
    "Angular/PHP/MySQL",
    "Xamarin/Ionic/Cordova/Capacitor",
    "Web Components/Stencil",
    ".NET Core/ASP.NET MVC",
    "Razor/Blazor",
    "C#/C++/VBA/PHP",
    "Typescript/JavaScript/jQuery",
    "MS-Office/Access/Excel/Outlook",
    "SQL-Server",
    "Entity Framework",
    "Interfaces",
    "Extract-Transform-Load (ETL)",
    "HTML/CSS",
    "XML",
    "JSON",
    "Node.js/Express.js/Sequelize",
    "DevExpress",
    "Bootstrap",
    "Git",
    "..."]


  set printView(val: boolean) {
    const container: HTMLElement = this.elementRef_Container.nativeElement;
    if (val)
      container.classList.add("print-view");
    else
      container.classList.remove("print-view");
  }

  //private router: Router,
  constructor(private navbarService: NavbarService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute) {
    navbarService.imageUrl = "../../assets/img/Landschaft2.jpg";

    this.articleSubscription = route.params.subscribe(p => {
      this.showArticle = p['article'] ? p['article'].toLowerCase() : "";
    });
  }





  ngOnInit() {
    let t = (this.showArticle == "") ? 300 : 1;
    let st = timer(t, t).subscribe(() => {
      // console.log("timer:" + this.flyIn)
      if (this.flyInRequestIndex > this.flyInCurrentIndex) {
        this.flyInCurrentIndex++;
      }

      if (this.flyInCurrentIndex >= this.flyInMaxIndex) {
        st.unsubscribe();

        // console.log(this.showArticle);
        // this.openDialog(this.tempRef_FullStack);
        switch (this.showArticle) {
          case "fullstack": this.openDialog(this.templateRef_FullStack);
            break;
          case "mobileapps": this.openDialog(this.templateRef_MobileApps);
            break;
          case "datenbanken": this.openDialog(this.templateRef_Datenbanken);
            break;
          case "webdesign": this.openDialog(this.templateRef_Webdesign);
            break;
          case "access": this.openDialog(this.templateRef_Access);
            break;
          case "excel": this.openDialog(this.templateRef_Excel);
            break;
          case "keywords": this.openDialog(this.templateRef_Keywords);
            break;
        }

        this.showArticle = "";

      }
    });

    this.printViewSubscription = this.navbarService.printViewUpdated
      .subscribe((printView: boolean) => this.printView = printView);
  }

  ngAfterViewInit() {
    this.printView = this.navbarService.printView
  }

  ngAfterContentInit() {

  }

  ngOnDestroy() {
    if (this.articleSubscription) this.articleSubscription.unsubscribe();
    this.printViewSubscription.unsubscribe();
    if (this.dialogSubscription) this.dialogSubscription.unsubscribe();  
  }


  openDialog(templateRef: TemplateRef<{}>) {
   
    // das Routen verursacht einen ständigen Neuaufbau.
    // if(this.router.url !== "/home/" + path){
    //   this.router.navigate(["/home/" + path]);
    //   return;
    // };


    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.maxWidth = '95vw';
    dialogConfig.maxHeight = '85vh';
    dialogConfig.panelClass = 'my-dialog';

    // this.dialog.open(DialogComponent, dialogConfig);
    this.dialogRef = this.dialog.open(templateRef, dialogConfig);

    this.dialogSubscription = this.dialogRef.afterClosed().subscribe(
      data => this.router.navigate(["/home"])//console.log("Dialog output:", data)
  );   

  }

  closeDialog() {
    if (this.dialogRef) this.dialogRef.close();
    //this.router.navigate(["/home"]);
  }

  isInViewport(elem: HTMLElement, partially: boolean = true) {
    var bounding = elem.getBoundingClientRect();
    //console.log(elem);

    if (!partially)
      return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    else
      return (
        bounding.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.left <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom >= 0 &&
        bounding.right >= 0
      );


  };

  showElement(elem: HTMLElement, elemIndex: number) {
    this.flyInRequestIndex = this.flyInMaxIndex; // zeige sofort alles, sonst "ruckelt" es zu viel

    if (this.flyInCurrentIndex >= elemIndex)
      return true;

    if (this.isInViewport(elem)) {

      // if (elemIndex > this.flyInCurrentIndex)
      //   this.flyInRequestIndex = elemIndex;

      // if (this.flyInCurrentIndex + 1 == elemIndex)
      //   return true;
    }

    return false;
  }


}









