<div>
  <div #container class="container fixed-top">
    <nav #nav class="attop">
      <navbar class="screen-only"></navbar>
    </nav>

    <!-- [@scrollMe]="scrollMeState" (@scrollMe.done)="onScrollMeDone()" -->

    <!-- 
        gf 2020-11-17: *ngIf="isImageFixedOnTop" ==> *ngIf="isImageFixedOnTop && !!imageUrl"
        ==> kein Platzhalter für leeres Bild (==> Impressum) 
    -->
    <div
      class="container imageContainer screen-only"
      *ngIf="isImageFixedOnTop && !!imageUrl"
      (click)="startAnimation()"
    >
      <div #myDivForBackgroundPic [style.background]="'url(' + imageUrl + ')'">
        <!-- <div #myDiv> -->
        <!-- siehe css: #imageContainer>div  -->
        <div
          style="
            font-size: 2.5rem;
            color: white;
            font-style: italic;
          "
        >
          <!-- <marquee
            direction="left"
            bgcolor="transparent"
            width="100%"
            height="55"
            scrollamount="4"
          >
            &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;bleibt gesund&nbsp;&nbsp;&nbsp;
            -&nbsp;&nbsp;&nbsp;schützt euch und eure
            mitmenschen&nbsp;&nbsp;&nbsp; -&nbsp;&nbsp;&nbsp;haltet
            abstand&nbsp;&nbsp;&nbsp; -&nbsp;&nbsp;&nbsp;tragt
            masken&nbsp;&nbsp;&nbsp; -&nbsp;&nbsp;&nbsp;bleibt - wenn möglich -
            zu hause&nbsp;&nbsp;&nbsp; -
          </marquee> -->
        </div>
      </div>
    </div>
  </div>

  <div id="placeholder" class="screen-only">
    <div>
      <!-- Platzhalter-Div für die Navbar -->
    </div>
    <div *ngIf="isImageFixedOnTop">
      <!-- Platzhalter-Div für den Image-Container (falls dieser fixed-top ist) -->
    </div>
  </div>
</div>

<!-- [@scrollMe]="scrollMeState" (@scrollMe.done)="onScrollMeDone()" -->
<div class="container screen-only">
  <!-- 
        gf 2020-11-17: *ngIf="isImageFixedOnTop" ==> *ngIf="isImageFixedOnTop && !!imageUrl"
        ==> kein Platzhalter für leeres Bild (==> Impressum) 
    -->
  <div class="imageContainer" *ngIf="!isImageFixedOnTop && !!imageUrl">
    <div #myDiv2ForBackgroundPic [style.background]="'url(' + imageUrl + ')'">
      <!-- <div #myDiv2> -->
      <!-- siehe css: #imageContainer>div  -->
      <br />
      <div
          style="
            font-size: 2.5rem;
            color: white;
            font-style: italic;
          "
        >
          <!-- <marquee
            direction="left"
            bgcolor="transparent"
            width="100%"
            height="55"
            scrollamount="4"
          >
            &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;bleibt gesund&nbsp;&nbsp;&nbsp;
            -&nbsp;&nbsp;&nbsp;schützt euch und eure
            mitmenschen&nbsp;&nbsp;&nbsp; -&nbsp;&nbsp;&nbsp;haltet
            abstand&nbsp;&nbsp;&nbsp; -&nbsp;&nbsp;&nbsp;tragt
            masken&nbsp;&nbsp;&nbsp; -&nbsp;&nbsp;&nbsp;bleibt - wenn möglich -
            zu hause&nbsp;&nbsp;&nbsp; -
          </marquee> -->
        </div>
    </div>
    <br />
  </div>
</div>
