<div class="container">
  <footer class="text-white-50 row">
    <div class="col-12 footerelement">
        <a class="nav-link" [routerLink]="['/impressum']">Impressum / Kontakt</a>
    </div>
    <div class="col-12 footerelement">
      <p>Copyright &copy; 2019-2024 Fritz-IT, Perl. Alle Rechte vorbehalten.</p>
    </div>
  </footer>
</div>

<div *ngIf="false">
     <!-- https://medium.com/@JSantaCL/pure-bootstrap-sticky-footer-with-flex-and-angular-e207abf505c7  -->
</div>