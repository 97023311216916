import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  private _imageUrl: string = "../../assets/img/Landschaft1.jpg";
  private _printView: boolean = false;

  constructor() {
  }

  imageUrlUpdated = new EventEmitter<string>();
  printViewUpdated = new EventEmitter<boolean>();

  public get imageUrl(): string {
    return this._imageUrl;
  }

  public get printView(): boolean {
    return this._printView;
  }

  public set imageUrl(imageUrl: string) {
    this._imageUrl = imageUrl;
    this.imageUrlUpdated.emit(imageUrl);
  }

  public set printView(printView: boolean) {
    this._printView = printView;
    this.printViewUpdated.emit(printView);
  }
}
