
<div class="container" class="navTrigger" (click)="onNavTriggerClick()" [ngSwitch]="animationType">
    <ng-container *ngSwitchCase="'bigbang'">
        <i [@bigBang1]=isX [style.background-color]="color"></i>
        <i [@bigBang2]=isX [style.background-color]="color"></i>
        <i [@bigBang3]=isX [style.background-color]="color"></i>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <!-- scissors -->
        <i [@scissors1]=isX [style.background-color]="color"></i>
        <i [@scissors2]=isX [style.background-color]="color"></i>
        <i [@scissors3]=isX [style.background-color]="color"></i>
    </ng-container>
</div>

