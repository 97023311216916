<div class='container impressum'>
    <br><br>
    <h1>Contasy Datenschutzerklärung</h1>
    <p>Angaben gemäß § 5 TMG</p>
    <p>
        Dipl.-Ing.(FH) Günter Fritz<br>
        Auf Horngarten 10<br>
        66706 Perl<br>
    </p>
    <p> <strong>Vertreten durch: </strong><br>
        Dipl.-Ing.(FH) Günter Fritz<br>
    </p>
    <p><strong>Kontakt:</strong> <br>
        Telefon: +49 6867 4669821<br>
        E-Mail: <a href='mailto:kontakt@fritz-it.eu'>kontakt@fritz-it.eu</a><br>
    </p>
    <p><strong>Umsatzsteuer-ID: </strong> <br>
        Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz: DE316284044.<br>
    </p>
    <p><strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong><br>
        Dipl.-Ing.(FH) Günter Fritz<br>
        Auf Horngarten 10<br>
        66706 Perl<br>
    </p>
    <p class="dse"><strong>Datenschutzerklärung: </strong><br><br>
        <strong>Contasy sammelt keine Daten und trackt keine Benutzer.</strong>
        <br><br>
        Contasy ist ein Werkzeug, das dazu dient, Kontaktdaten zwischen einem Datenbankserver und einem oder mehreren
        mobilen Geräten zu übertragen und zu synchronisieren.
        <br><br>
        Die Datenübertragung findet dabei verschlüsselt und ausschließlich zwischen Geräten statt, die im Verantwortungsbereich des
        Benutzers stehen.
        <br><br>
        Zu keinem Zeitpunkt werden Kontaktdaten oder andere personenbezogene Daten von der App an den Entwickler oder an
        Dritte übertragen.
    </p>
</div>