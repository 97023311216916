// https://code-maze.com/authentication-aspnetcore-jwt-2/
// https://stackoverflow.com/questions/49109795/getting-error-in-jwthelperservice

import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ToolsService } from './tools.service';
import * as jwt_decode from 'jwt-decode';


@Injectable()
export class AuthService {
  private roles: string[] = [];
  //private token: string;

  constructor(
    private router: Router,
    private http: HttpClient,
    private tools: ToolsService) {
  }

  isInRole(role: string) {
    return this.roles.indexOf(role.toLowerCase()) > -1;
  }

  getToken() {
    return localStorage.getItem("token");
  }

  private setToken(token: string) {
    localStorage.setItem("token", token);
    this.getRolesFromToken();
  }

  private getRolesFromToken() {
    const token = this.getToken();

    let decodedToken = this.getDecodedAccessToken(token)
    const r = decodedToken['data']['roles'];

    if (this.tools.IsNullOrWhitespace(r))
      this.roles = ["-"]
    else
      this.roles = r.split(",").map(s => s.trim().toLowerCase()).sort();

    //console.log(this.roles);
    // || ["-"]:um unterscheiden zu können, ob die Rollen schon abgefragt wurden, aber einfach leer sind ...

  }


  getHttpAuthHeaders() {
    const token = this.getToken();
    return {
      headers: new HttpHeaders().set("Authorization", `Bearer ${token}`)
    };
  }

  authenticated() {
    //if (isDevMode()) return true;

    const token = this.getToken();

    if (token && !this.isTokenExpired(token)) {
      if (this.roles.length == 0) {
        this.getRolesFromToken();
      }
      return true;
    }

    return false;
  }

  login(credentials?: { username: string, password: string }) {
    if (!credentials) {
      this.router.navigate(["/login"]);
      return;
    }


    // https://medium.com/@luukgruijs/understanding-creating-and-subscribing-to-observables-in-angular-426dbf0b04a3
    // create observable
    const simpleObservable = new Observable<any>((observer) => {

      //let url = !isDevMode() ? "https://fritz-it.eu/api/login.php" : "https://diskstation:8004/login.php";
      //let url = !isDevMode() ? "https://fritz-it.eu/api/login.php" : "https://diskstation:4444/apitest/api/login.php";
      //let url = !isDevMode() ? "api/login.php" : "https://diskstation:8005/api/login.php";

      let url = "api/login.php";
      
      //this.http.post("", JSON.stringify(credentials), {
      this.http.post(url, JSON.stringify(credentials), {
        // this.http.get("https://fritz-it.eu/apitest/api.php/test/1", {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      }).subscribe(response => {
        const token = (<any>response).jwt;
        this.setToken(token);

        // observable execution
        observer.next()
        observer.complete()
      }, err => {
        // observable execution
        observer.next(err)
        observer.complete()
      });
    })

    return simpleObservable;
  }

  // private getRoles(token: string) {
  //   this.http.get("/api/auth/roles", this.getHttpAuthHeaders())
  //     .subscribe((r: string[]) => {
  //       this.roles = r;
  //       if (this.roles.length == 0)
  //         this.roles.push("-"); // um unterscheiden zu können, ob die Rollen schon abgefragt wurden, aber einfach leer sind ...
  //     });

  // }


  logout() {
    localStorage.removeItem("token");
    this.roles = [];
  }


  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  isTokenExpired(token: string): boolean {
    let tokenInfo = this.getDecodedAccessToken(token); // decode token
    let expireDate = tokenInfo.exp; // get token expiration dateTime (in seconds)

    return 1000 * expireDate < Date.now();
  }


}

